.footer-title {
	font-size: 16px;
	font-family: 'HK Grotesk Bold';
	color: #071435;
}
#footer {
	padding-top: 80px;
	padding-bottom: 80px;
}
#footer-logo {
	font-family: 'HK Grotesk Extra Bold';
	font-size: 22px;
	line-height: 30px;
	color: #071435;
	padding-top: 48px;
}
@media (max-width: 575px) {
  .c-pt-4 {
    padding-top: 2rem
  }
}
@font-face {
  font-family: 'HK Grotesk Bold';
  src:  url('./fonts/HKGrotesk-Bold.woff2') format('woff2');
	font-display: swap;
}
@font-face {
  font-family: 'HK Grotesk Extra Bold';
  src:  url('fonts/HKGrotesk-ExtraBold.woff2') format('woff2');
	font-display: swap;
}
