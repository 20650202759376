#custom-btn:hover {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important
  /* mb have it change colour instead? */
}
html {
    scroll-behavior: smooth;
}
@font-face {
  font-family: 'HK Grotesk Bold';
  src:  url('../fonts/HKGrotesk-Bold.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'HK Grotesk Regular';
  src:  url('../fonts/HKGrotesk-Regular.woff2') format('woff2');
  font-display: swap;
}
