nav {
  background: #f8f8f8;
}
.center-navbar {
  width: 30%;
  margin-left: 35%;
}
.navbar-brand {
  font-family: 'HK Grotesk Extra Bold';
  font-size: 22px !important;
  font-weight: 400;
}
.nav-link {
  font-family: 'HK Grotesk Medium';
  opacity: .9;
  color: #071435 !important;
}
.twemoji {
  width: 1.5rem
}
#navdropdown::after {
    content: none;
}
.dropdown-menu[data-bs-popper] {
  left: -100% !important
}
@media (max-width: 540px) {
  .center-navbar {
  	width: unset;
  	margin-left: unset;
  }
}
@font-face {
  font-family: 'HK Grotesk Extra Bold';
  src:  url('fonts/HKGrotesk-ExtraBold.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'HK Grotesk Medium';
  src:  url('fonts/HKGrotesk-Medium.woff2') format('woff2');
  font-display: swap;
}
