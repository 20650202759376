#contactless {
  padding: 6rem 4.5rem 6rem 4.5rem;
}
.opacity-low {
  background: rgba(255, 255, 255, 0.7) !important
}
.hide-big {
  display: none
}
@media (max-width: 540px) {
  .hide-small {
    display: none;
  }
  .hide-big {
    display: inline;
  }
}
@media (max-width: 766px) {
  .c-pt-2 {
    padding-top: 1rem
  }
}
@media (max-width: 575px) {
  #green {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }
}
