.offwhite {
  background: #f8f8f8;
}
#main-wrap {
  padding: 50px 0 80px 0;
}
.title {
  font-family: 'HK Grotesk Bold';
  color: #071435 !important;
  margin-bottom: 24px !important
}
.subtitle {
  font-family: 'HK Grotesk Regular';
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 24px
}
.custom-btn {
  cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: white;
	-webkit-letter-spacing: 1px;
	-moz-letter-spacing: 1px;
	-ms-letter-spacing: 1px;
	letter-spacing: 1px;
	height: 60px;
	display: block;
	text-transform: uppercase;
	cursor: pointer;
	white-space: nowrap;
	background: #098c8c;
	border-radius: 4px;
	padding: 0px 40px;
	border-width: 0px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
	outline: 0px;
}
